<div class="masthead text-white text-center">
    <img
        class="img-fluid mb-5 d-block mx-auto"
        src="https://robvankeilegom.be/images/profile_picture.jpg"
        alt=""
    >
    <h1 class="text-uppercase mb-0">Rob Van Keilegom</h1>
    <hr class="star-light">
    <h2 class="font-weight-light mb-5">Full Stack Developer - Software Engineer</h2>
    <h2>
        <a
            href="https://robvankeilegom.be"
            target="_blank"
            rel="noreferrer"
        >https://robvankeilegom.be</a>
    </h2>
</div>
