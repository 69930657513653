import { BrowserModule } from '@angular/platform-browser';
import { NgModule, OnInit, Directive } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { CounterComponent } from './counter/counter.component';

import { JQ_TOKEN, CHART_TOKEN, ApiService } from './common/barrel';

import 'fullpage.js';
import { ChartComponent } from './chart/chart.component';
import { InfoComponent } from './info/info.component';

let jQuery = window[ '$' ];
let Chart = window[ 'Chart' ];

@NgModule( {
  declarations: [
    AppComponent,
    CounterComponent,
    ChartComponent,
    InfoComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
  ],
  providers: [
    ApiService,
    { provide: JQ_TOKEN, useValue: jQuery },
    { provide: CHART_TOKEN, useValue: Chart },
  ],
  bootstrap: [ AppComponent ]
} )
export class AppModule implements OnInit {

  ngOnInit () {
  }
}
