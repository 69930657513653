import { Component, AfterViewInit, Inject } from '@angular/core';
import { JQ_TOKEN } from './common/barrel';

@Component( {
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
} )
export class AppComponent implements AfterViewInit {

  constructor (
    @Inject( JQ_TOKEN ) private $: any
  ) { }

  ngAfterViewInit () {
    this.$( '#fullpage' ).fullpage( {
      navigation: true
    } );
  }
}
