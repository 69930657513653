<div class="container">
    <div
        class="col-lg-12 text-center md-5"
        *ngIf="!wm"
    >
        <i class="fas fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div
        class="col-lg-12 text-center md-5"
        *ngIf="wm"
    >
        <h1 *ngIf="wm?.data">{{ wm?.data.km }} KM</h1>
        <small class="fact">{{ wm?.fact }}</small>
        <small>
            <!-- Laatste update: {{ $data->updated_at->format('d/m/Y H:i:s') }}
    Volgende update: {{ $data->updated_at->addMinutes(5)->format('d/m/Y H:i:s') }} -->
        </small>
    </div>
    <div
        class="row text-center mt-5 mam"
        *ngIf="wm?.data"
    >
        <div
            class="col-xs-2 col-xs-offset-3"
            data-toggle="tooltip"
            title="Minimum van vandaag"
        >
            <span *ngIf="minimum">
                <i class="fas fa-level-down-alt"></i> {{ minimum }}KM
            </span>
        </div>
        <div
            class="col-xs-2 ml-2 mr-2"
            data-toggle="tooltip"
            title="Gemiddelde van vandaag"
        >
            <span *ngIf="average">
                <i class="fas fa-car"></i> {{ average }}KM
            </span>
        </div>
        <div
            class="col-xs-2"
            data-toggle="tooltip"
            title="Maximum van vandaag"
        >
            <span *ngIf="maximum">
                <i class="fas fa-level-up-alt"></i> {{ maximum }}KM
            </span>
        </div>
    </div>
</div>
<i class="fa fa-angle-down"></i>
