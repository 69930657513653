import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const options = {
  headers: new HttpHeaders( { 'Content-Type': 'application/json' } )
};

@Injectable()
export class ApiService {

  private baseUrl: string = 'https://api.hfse.robvankeilegom.be/api/';

  constructor ( private http: HttpClient ) { }

  getWelcomeMessage () {
    return this.http.post( this.baseUrl + 'getWelcomeMessage', [], options )
      .pipe(
        catchError( this.handleError( [] ) )
      );
  }

  getData ( filter: {} ) {
    return this.http.post( this.baseUrl + 'getData', filter, options ).pipe(
      catchError( this.handleError( filter ) )
    );
  }

  private handleError<T>( data, result?: T ) {
    return ( error: any ): Observable<T> => {
      console.error( error );
      return of( result as T );
    };
  }
}
