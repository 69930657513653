import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { JQ_TOKEN, CHART_TOKEN, ApiService } from '../common/barrel';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component( {
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: [ './chart.component.scss' ]
} )
export class ChartComponent implements AfterViewInit {
  apiFormat = 'YYYY-MM-DD';

  public chart;
  public startDate: string;
  public endDate: string;
  public type: string;

  public labels = [];
  public data = [];

  constructor (
    private API: ApiService,
    @Inject( JQ_TOKEN ) private $: any,
    @Inject( CHART_TOKEN ) private Chart: any,
  ) {
    this.startDate = moment().subtract( 7, 'days' ).format( this.apiFormat );
    this.endDate = moment().format( this.apiFormat );
    this.type = 'average';
  }


  ngAfterViewInit () {
    this.$( '.type-buttons input[name=types]' ).change( ( e ) => {
      this.setType( e );
    } );

    this.updateDates().subscribe( () => this.createGraph() );
  }

  public setPreset ( preset: string ) {
    this.data = [];
    this.labels = [];
    switch ( preset ) {
      case 'today':
        this.startDate = moment().format( this.apiFormat );
        break;
      case 'lastweek':
        this.startDate = moment().subtract( 7, 'days' ).format( this.apiFormat );
        break;
      case 'lastmonth':
        this.startDate = moment().subtract( 1, 'months' ).format( this.apiFormat );
        break;

      default:
        break;
    }
    this.endDate = moment().format( this.apiFormat );
    this.updateGraph();
  }


  public updateGraph () {
    this.updateDates().subscribe( () => {
      this.chart.data.datasets = this.data;
      this.chart.data.labels = this.labels.slice( 0 );

      this.chart.update();
    } );
  }

  private updateDates (): Observable<any> {

    const data = {
      start: this.startDate,
      end: this.endDate,
      type: this.type,
    };
    const label = this.startDate + ' - ' + this.endDate;

    return this.API.getData( data ).pipe(
      map( ( result: any ) => {
        let color = 'rgba(' + ( Math.floor( Math.random() * 256 ) );
        color += ',' + ( Math.floor( Math.random() * 256 ) );
        color += ',' + ( Math.floor( Math.random() * 256 ) ) + ', .5)';

        const labels = ( result || <any>{} ).labels;

        const filteredResult = [];
        const filteredLabels = [];

        if ( labels.length === 0 ) { return; }
        result = ( result || <any>{} ).data;

        if ( this.labels.length === 0 ) {
          this.labels = labels.slice( 0 );
        }

        if ( labels.length !== this.labels.length ) {
          labels.forEach( el => {
            const index = this.labels.findIndex( o => o === el );
            if ( index !== -1 ) {
              this.labels.push( el );
            }

          } );
          this.labels.sort();

          this.chart.data.datasets.forEach( dataset => {
            dataset.labels = this.labels;
          } );
          // result.forEach(element => {
          //   index = this.labels.findIndex(o => o === element.x);
          //   if (index !== -1) {
          //     filteredResult.push(element);
          //   }

          // });

          // let deleted = 0;
          // this.labels.forEach(element => {
          //   index = labels.findIndex(o => o === element);
          //   if (index !== -1) {
          //     this.chart.data.datasets.forEach(dataset => {
          //       dataset.labels.splice(index - deleted, 1);
          //       dataset.data.splice(index - deleted, 1);
          //     });
          //     deleted++;
          //     filteredLabels.push(element);
          //   }
          // });

          // this.labels = filteredLabels;
          // result = filteredResult;
        }

        this.data.push( {
          label: label,
          labels: this.labels,
          data: result,
          backgroundColor: color,
          fill: true,
          borderWidth: 5
        } );
      } )
    );
  }

  private setType ( e ) {
    this.data = [];
    this.labels = [];
    e = this.$( '.type-buttons input[name=types]:checked' );
    if ( e.length ) {
      this.type = ( e[ 0 ] || {} ).id;
    } else {
      e = this.$( '.type-buttons input[name=types]' ).prop( 'checked', false );
      this.type = 'average';
      ( this.$( '#' + this.type ) || {} ).prop( 'checked', true );
    }
    this.updateGraph();
  }

  private createGraph () {
    if ( this.chart && typeof this.chart.destroy === 'function' ) {
      this.chart.destroy();
    }

    const canvas = <HTMLCanvasElement>this.$( '#chart' ).get( 0 );
    const ctx = canvas.getContext( '2d' );

    this.chart = new this.Chart( ctx, {
      type: 'line',
      data: {
        labels: this.labels.slice( 0 ),
        datasets: this.data
      },
      options: {
        legend: {
          // display: false,
          position: 'bottom'
        },
        scales: {
          yAxes: [ {
            gridLines: {
              color: '#444'
            },
            ticks: {
              beginAtZero: true,
              fontColor: '#444'
            }
          } ],
          xAxes: [ {
            gridLines: {
              display: false,
              color: '#444'
            },
            ticks: {
              fontColor: '#444'
            }
          } ]
        },
        responsive: true,
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function ( tooltipItems ) {
              return tooltipItems.yLabel + ' KM';
            }
          }
        },
        hover: {
          mode: 'nearest',
          intersect: true
        }
      }
    } );
  }

  public reset () {
    this.data = [];
    this.labels = [];
    this.updateDates().subscribe( () => {
      this.chart.data.datasets = this.data;
      this.chart.data.labels = this.labels.slice( 0 );

      this.chart.update();
    } );
  }
}
