import { Component, OnInit, ViewEncapsulation, AfterViewInit, Inject } from '@angular/core';
import { ApiService, IWelcomeMessage, JQ_TOKEN } from '../common/barrel';
import { IData } from '../common/barrel';

@Component( {
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: [ './counter.component.scss' ],
} )
export class CounterComponent implements OnInit, AfterViewInit {
  wm: IWelcomeMessage;
  minimum: number;
  average: number;
  maximum: number;

  constructor (
    private API: ApiService,
    @Inject( JQ_TOKEN ) private $: any
  ) { }

  ngOnInit () {
    this.API.getWelcomeMessage().subscribe( ( result: any ) => {
      this.wm = <IWelcomeMessage>result;
      if ( this.wm && this.wm.data ) {
        this.wm.data.km = Math.round( this.wm.data.m / 10 ) / 100;
      }

      this.minimum = result.minimum;
      this.average = result.average;
      this.maximum = result.maximum;
    } );
  }

  ngAfterViewInit () {
    this.$( '[data-toggle="tooltip"]' ).tooltip();
  }

}
