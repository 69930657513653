<div class="container-element">
    <div class="col-lg-12 text-center mb-2 header">
        <div class="form-group inline float-left d-flex flex-row text-left">
            <div class="d-flex flex-column">
                <label for="startDate">
                    Start Datum
                </label>
                <input
                    type="date"
                    name="startDate"
                    [(ngModel)]="startDate"
                    class="form-control mr-2"
                >
            </div>

            <div class="d-flex flex-column">
                <label for="endDate">
                    Eind Datum
                </label>
                <input
                    type="date"
                    name="endDate"
                    [(ngModel)]="endDate"
                    class="form-control mr-2"
                >
            </div>
            <div class="d-flex flex-row">
                <button
                    type="button"
                    class="btn btn-success mr-2 w-100"
                    (click)="updateGraph()"
                >
                    Toevoegen
                </button>
                <button
                    type="button"
                    class="btn btn-warning"
                    (click)="reset()"
                >
                    Reset
                </button>
            </div>
        </div>
        <div class="form-group inline float-none type-buttons">
            <div
                class="btn-group btn-group-toggle ml-auto"
                data-toggle="buttons"
            >
                <label class="btn btn-light">
                    <input
                        type="radio"
                        name="types"
                        autocomplete="off"
                        id="minimum"
                    > Minimum
                </label>
                <label class="btn btn-light active">
                    <input
                        type="radio"
                        name="types"
                        autocomplete="off"
                        id="average"
                        checked
                    > Gemiddelde
                </label>
                <label class="btn btn-light">
                    <input
                        type="radio"
                        name="types"
                        autocomplete="off"
                        id="maximum"
                    > Maximum
                </label>
            </div>
        </div>
        <div class="form-group inline float-right">
            <div class="btn-group ml-auto">
                <button
                    type="button"
                    class="btn"
                    (click)="setPreset('today')"
                >
                    Vandaag
                </button>
                <button
                    type="button"
                    class="btn"
                    (click)="setPreset('lastweek')"
                >
                    Vorige week
                </button>
                <button
                    type="button"
                    class="btn"
                    (click)="setPreset('lastmonth')"
                >
                    Vorige maand
                </button>
            </div>
        </div>
    </div>
    <canvas id="chart"></canvas>
</div>
